.homer {
  min-height: 100vh;
  background: url(../public/reservebg.jpg) center/cover;
}

.flexer {
  display: flex;
}

.hero-reserve {
  width: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay {
  background: rgba(125, 160, 0, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.form-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  width: 100%;
}

.hero-reserve h3,
.hero-reserve p {
  color: #fff;
  letter-spacing: 0.03em;
  line-height: 24px;
}

.hero-reserve hr {
  width: 100%;
  color: white;
  margin: 2%;
}

.form-reserve {
  flex-direction: column;
  margin: 5%;
  align-items: center;
  width: 70%;
  box-sizing: border-box;
}

.abs {
  width: 100%;
  gap: 10px;
}

.form-reserve button {
  border: none;
  background-color: #e8eed6;
  color: rgb(125, 160, 0);
  cursor: pointer;
}

.form-container form select,
input {
  background-color: rgba(150, 191, 1, 0.7);
  color: #e8eed6;
}

.reservation-card button {
  background-color: #d9534f;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.container form select,
.container form input,
.container form button {
  border-radius: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  width: 100%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  box-sizing: border-box;
}

.form-container form select option {
  background-color: rgb(125, 160, 0);
  color: #fff;
  border: none;
}

.form-container form input::placeholder,
.form-container form select::placeholder {
  color: #e8eed6;
}

.output {
  color: #e8eed6;
}

/* all reservations */
.reserve {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-x: hidden;
}

.scroll-container {
  display: flex;
  align-items: center;
  overflow-x: hidden;
  width: 80%;
  margin-left: 40%;
  box-sizing: border-box;
}

.all-reservations {
  list-style: none;
  transition: transform 0.3s ease-in-out;
  gap: 20px;
}

.all-reservations li:first-child {
  margin-left: 3%;
}

.reservation-card {
  flex-direction: column;
  width: 200px;
  height: 400px;
  padding: 15px;
  margin: 10px 10px;
  box-sizing: border-box;
}

.doctor-image {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-color: #ccc;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.doctor-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.no-image {
  color: #999;
  font-style: italic;
}

.doctor-name {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 5px;
}

.additional-details {
  display: flex;
  flex-direction: column;
}

.specialisation,
.appointment-date,
.city {
  font-size: 0.8em;
  margin: 6px 0;
}

.scroll-button {
  position: fixed;
  background: #9acd32;
  color: #fff;
  font-size: larger;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  top: 40%;
  z-index: 2;
}

.left {
  left: 0;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.right {
  right: 0;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.clicked {
  color: #e8eed6;
  background-color: #9acd32;
}

.temporary {
  background-color: #9acd32;
  color: white;
}

.center {
  text-align: center;
  margin-bottom: 5%;
}

@media screen and (min-width: 768px) {
  .exclusive {
    margin-left: 10%;
  }

  .form-reserve {
    width: 50%;
  }

  .overlay {
    width: 80%;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
  }

  .hero-reserve hr {
    width: 60%;
  }

  .container form input {
    padding: 18px;
  }

  .container form select,
  .container form button {
    padding: 12px;
  }

  .scroll-container {
    width: 100%;
    margin-left: 32%;
  }

  .desk_nav {
    width: 20%;
    height: 100vh;
    box-shadow: 10px 10px 15px rgba(6, 2, 2, 0.2);
    z-index: 10;
  }

  .scroll-button {
    padding: 10px 20px;
    cursor: pointer;
  }

  .left {
    left: 20%;
  }
}

@media screen and (min-width: 1440px) {
  .scroll-container {
    width: 100%;
    margin-left: 20%;
  }

  .scroll-button {
    padding: 20px 40px;
    cursor: pointer;
  }
}
