* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
}

.home_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2,
.each_doc_name {
  color: #222;
}

p {
  color: #636363;
  text-align: center;
}

.home_header > h2 {
  margin-bottom: 0;
}

.home_header > p {
  margin-bottom: 40px;
}

.homepage_content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}

.allDoctors {
  display: flex;
  overflow-x: hidden;
  padding: 20px 0;
  gap: 20px;
  width: 80%;
  height: 100%;
}

.social-links {
  display: flex;
  gap: 10px;
  padding: 0;
  width: 50%;
  margin: auto;
}

.social-link {
  list-style: none;
  color: #ddd;
}

.eachDoctor {
  width: 200px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img_div {
  width: 200px;
  height: 150px;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.prev_btn {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-right: 20px;
}

.next_btn {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left: 20px;
}

.prev_btn,
.next_btn {
  color: #fff;
  font-size: 50px;
  background: #ddd;
  border-color: transparent;
  padding: 0 8px;
  cursor: pointer;
  transition: all 0.3s linear;
}

.prev_btn:hover,
.next_btn:hover {
  background-color: #9acd32;
}

a {
  text-decoration: none;
  color: #333;
}

.dots {
  display: flex;
  gap: 5px;
  margin-bottom: 30px;
}

.dot {
  width: 5px;
  height: 5px;
  background-color: #ddd;
  border-radius: 2px;
}

@media screen and (max-width: 768px) {
  /* homepage */
  .homepage_content {
    justify-content: center;
  }

  .eachDoctor {
    width: 80%;
    margin: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .homepage_flex {
    justify-content: center;
  }

  .desk_nav {
    display: none;
  }

  .doctors_div {
    width: 100%;
  }

  /* navigation */

  .mobile_nav {
    display: block !important;
    position: absolute;
    top: 0;
    left: 0;
  }

  .mobile_menu {
    display: none;
  }

  .mobile_menu.open {
    display: block;
    height: 100vh;
    width: 100vw;
    background-color: #ebede7;
    margin: 0;
    position: absolute;
    top: 0;
    z-index: 3;
  }

  .hamburger {
    background-color: transparent;
    border: none;
    font-size: 25px;
    margin-top: 50%;
    margin-left: 50%;
  }

  .close_menu {
    background-color: #ebede7;
    border: none;
    font-size: 25px;
    margin-top: 30px;
    margin-left: 20px;
  }

  .each_nav:hover {
    color: #fff;
  }

  .logout_btn {
    background-color: #ebede7 !important;
  }

  .splash_page,
  .regis_page {
    width: 90% !important;
    margin: auto;
  }
}

/* splash page */
.splash_page {
  width: 50vw;
  border: #636363 1px solid;
  margin: auto;
  margin-top: 10%;
  border-radius: 5px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-shadow: 5px 5px 5px #9acd32;
}

.splash_links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.splash_link {
  width: 200px;
  margin: auto;
  padding: 20px;
  background-color: #9acd32;
  border-radius: 5px;
  text-align: center;
  border: none;
}

/* login */

form {
  height: 250px;
  display: flex;
  flex-direction: column;
}

.h2_login {
  height: 50px;
  margin: 0;
}

input {
  width: 100%;
  height: 20px;
}

.login_btn {
  width: 240px;
}

/* registration */
.regis_page {
  width: 50vw;
  border: #636363 1px solid;
  margin: auto;
  margin-top: 5%;
  border-radius: 5px;
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 5px 5px 5px #9acd32;
}

.reg_error,
.login_error {
  margin: 0;
  color: red;
}

.reg_btns {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 120px;
}

.reg_success {
  display: flex;
  flex-direction: column;
}

/* Navigation */

.mobile_nav {
  display: none;
}

.Navbar {
  height: 100%;
}

.logout_btn {
  width: 100%;
  padding: 10px 5px;
  color: #333;
  background-color: #fff;
  text-align: start;
  border: none;
}

.logout_btn:hover {
  background-color: red;
  color: #fff;
}

.nav_h4 {
  height: 20%;
  margin-left: 10%;
}

nav {
  display: flex;
  flex-direction: column;
  height: 70%;
  margin-left: 10%;
  gap: 5%;
}

.nav_social_links {
  height: 10%;
  margin-left: 10%;
}

.each_nav {
  padding: 10px 0;
}

.each_nav:hover {
  background-color: #9acd32;
}

.homepage_flex {
  display: flex;
  width: 100%;
  height: 100%;
}

.desk_nav {
  width: 20%;
  height: 100vh;
  box-shadow: 10px 10px 15px rgba(6, 2, 2, 0.2);
}

@media screen and (min-width: 768px) {
  .doctors_div {
    width: 80%;
  }
}
