.details-wrapper {
  display: grid;
  flex-direction: column;
  min-height: 100vh;
  grid-template-rows: 0fr 1fr;
}

.details-container {
  min-height: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  place-content: center;
  padding: 0.7rem 2rem;
}

.notice {
  place-self: flex-start;
  align-self: flex-start;
}

.delete-btn {
  margin-top: 1rem;
  margin-right: 1rem;
  place-self: flex-end;
  padding: 0.5rem 1.2rem;
  border: none;
  border-radius: 1rem;
  background-color: #c70000;
  color: #fafafa;
}

.doc-img-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  max-height: 500px;
  max-width: 700px;
}

.doc-img-container img {
  border-radius: 30px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .details-container {
    grid-template-columns: 1fr;
  }
}
